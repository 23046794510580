import _get from "lodash.get";

const get = (obj, path) => {
  const raw = _get(obj, path, "");
  return (raw || "").trim();
};

const defaults = {
  main_ingredients: "B12 + GINSENG + 5-HTP",
  size: "500 mg CBD",
  dosage: "1 oz / 30ml",
  suggested_use: `<h6>SUGGESTED USE</h6><p>Adults: Shake well before use. Place one full dropper under your tongue for 20-30 seconds then swallow. Repeat up to two times daily or as needed. Increase as needed or as directed by a medical professional. For oral use only. Please store in a cool, dry place away from direct sunlight.</p><h6>CAUTION</h6><p>Keep out of reach of children. Consult with your regular physician before using this product. Not intended for use by persons under the age of 18. If you experience any adverse symptoms after use, please discontinue and consult your doctor. 18+ to purchase.</p>`,
  supplement_facts_before: `<h3>Supplement Facts</h3><p>Serving Size 0.5 ml</p><p>Serving per container: 60</p>`,
  ingredients_amounts: require("json5-loader!./ingredients-amounts.json5"),
  supplement_facts_after: `<p>* Based on a 2,000 calorie diet.</p><p>† Daily Value not established.</p><hr /><p>Other Ingredients: Organic Hemp Seed Oil, Organic MCT Coconut Oil, Organic Hemp Oil, Organic Mango Extract.</p><hr /><p>The product name “energize” is not an indication or claim to any function. These statements have not been evaluated by the Food & Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.</p><hr />`
};

export default function parseProductAcf (product) {

  const data = {
    main_ingredients: get(product, "acf.main_ingredients") || defaults.main_ingredients,
    size: get(product, "acf.size") || defaults.size,
    dosage: get(product, "acf.dosage") || defaults.dosage,
    suggested_use: get(product, "acf.suggested_use") || defaults.suggested_use,
    supplement_facts_before: get(product, "acf.supplement_facts_before") || defaults.supplement_facts_before,
    supplement_facts_after: get(product, "acf.supplement_facts_after") || defaults.supplement_facts_after,
    effect: get(product, "acf.effect") || ""
  };

  // Ingredients + Amounts json
  const acf_ingredients_amounts_json = get(product, "acf.ingredients_amounts");
  if (acf_ingredients_amounts_json) {
    try {
      data.ingredients_amounts = JSON.parse(acf_ingredients_amounts_json);
    } catch (e) {
      // JSON parse error
      data.ingredients_amounts = defaults.ingredients_amounts;
    }
  } else {
    data.ingredients_amounts = defaults.ingredients_amounts;
  }

  return data;
}
