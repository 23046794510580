import React, { Component } from "react";
import "./reviews-summary.scss";
import ReviewsSummaryRow from "./reviews-summary-row";
import Rating from "react-rating";

export default class ReviewsSummary extends Component {

  render () {

    const summary = this.props.summary;
    const avg_rating = this.props.avg_rating;
    const avgRating = this.props.summary.avg_rating.toFixed(1);

    return (
      <div className="reviews-summary">
        <div className="reviews-summary-bg" />
        <div className="reviews-summary-inner">
        <div className="columns is-mobile is-multiline">
            <div className="column">
              <h1>{avg_rating.toFixed(1)}</h1>
            </div>
            <div className="column">
              <Rating className="star-rating readonly has-text-white"
                            readonly={true}
                            initialRating={avg_rating}
                            emptySymbol={<label className="star-rating__star">☆</label>}
                            fullSymbol={<label className="star-rating__star is-selected">★</label>}/>
              <p className="has-text-white has-text-weight-bold">
                based on {summary.total_reviews}&nbsp;reviews
              </p>
            </div>
          </div>
          <br />
          <ReviewsSummaryRow title="5 " count={summary.starred_5} total={summary.total_reviews} />
          <ReviewsSummaryRow title="4 " count={summary.starred_4} total={summary.total_reviews} />
          <ReviewsSummaryRow title="3 " count={summary.starred_3} total={summary.total_reviews} />
          <ReviewsSummaryRow title="2 " count={summary.starred_2} total={summary.total_reviews} />
          <ReviewsSummaryRow title="1 " count={summary.starred_1} total={summary.total_reviews} />
        </div>
      </div>
    );
  }

}
