import React from "react";

const SvgRelatedProductBlob = props => {
  let fill, className = "oil-svg-background";
  switch (props.flavor) {
    case "cbd-oil-mint-bliss":
      fill = "#92d0aa"
      break
    case "cbd-oil-mighty-mango":
      fill = "#ff7f40"
      break

    case "cbd-oil-zesty-lemon":
      fill = "#fbdc00"
      break

    case "cbd-oil-french-vanilla":
      fill = "#f1ebbf"
      break
    case "cbd-topicals-pain-cream":
      fill = "#4B6BA5"
      break
    case "cbd-topicals-body-lotion":
      fill = "#FF7F40"
      break
    case "cbd-topicals-face-cream":
      fill = "#33C1F5"
      break 
    case "cbd-vegan-gummies-fresh-watermelon-300mg":
      fill = "#96C863"
      className = "vagan-gummies-svg-background"
      break
    case "cbd-vegan-gummies-acai-blast-300mg":
      fill = "#8B5573"
      className = "vagan-gummies-svg-background"
      break
    default:
      fill = "#951a4b"
      className = "vagan-gummies-svg-background"
      break
  }
  return(
  <svg {...props} className={className}>
    <path
      d="M300.84 296.14c-70.384 51.275-76.432 54.061-137.766 69.383-9.181 2.285-19.58 3.068-27.696-1.611-8.116-4.68-1.371-15.234-5.244-23.81-6.831-15.233-38.619 12.71-50.041.545-6.375-6.79 12.184-18.717 7.245-26.638-8.355-13.428-31.504 2.742-47.278 4.113a13.29 13.29 0 01-8.398-1.088c-6.527-4.114-1.806-14.146 3.35-19.892 8.1-9.041 27.554-20.35 21.035-30.108-12.086-18.089 28.195-26.92 23.682-35.54-8.684-16.587-48.153 22.113-64.211-5.279-6.275-10.704 34.439-16.87 21.301-29.885-13.137-13.014 32.115-20.682 25.042-32.815-10.161-17.411-43.95 33.08-60.92 4.483-8.224-14.103 38.967-57.39 83.264-89.948C123.585 49.105 225.638-9.164 235.24 1.662c9.6 10.826-12.06 22.11.061 35.652 12.122 13.543 30.01-9.08 53.683-17.525 3.916-1.393 8.702-2.677 11.944-.152a10.382 10.382 0 013.35 6.768c1.98 12.057-3.98 23.788-8.702 35.105-4.721 11.317-7.854 24.962-1.11 35.17 6.745 10.206 18.752-12.377 27.567-3.44 8.815 8.939-2.709 12.89 4.536 18.918 6.528 5.462 19.965-4.99 32.277-14.63 12.31-9.64 38.38-31.687 49.606-16.322 10.878 14.82-39.278 38.22-34.11 50.91 10.882 26.716-42.781 40.63-27.136 56.731 10.228 10.527 21.3-8.226 31.59 0 11.578 9.084-40.961 38.44-31.463 53.703 11.304 18.165-15.099 30.723-46.492 53.59z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
  )
};

export default SvgRelatedProductBlob;
