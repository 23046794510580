import React, { Component } from "react";
import Testimonials from "../testimonials";
import TestimonialsMobile from "../testimonials-mobile";


export default class TestimonialsSection extends Component {
  render () {
    return (
      <section className="section is-medium has-white-background">
        <div className="container">
          <Testimonials />
          <TestimonialsMobile/>
        </div>
      </section>
    );
  }
}
