import React, { Component, Fragment } from "react";
import Highlightable from "../highlightable";
import anime from "animejs";
import "./ProductBenefits.scss";

const blobStates = {
  1: {
    // d: "M119.55,35.62c4,19.46-19.29,40.32-44.27,57.92s-51.66,28-64.89,19.59S-2.68,67.64,6.63,41,34.42,2.22,59.3.16,115.52,16.16,119.55,35.62Z",

    // from https://app.svgator.com
    d: ["M", 119.55, 35.62, "C", 123.55, 55.08, 100.25999999999999, 75.94, 75.28, 93.53999999999999, "C", 50.30000000000001, 111.13999999999999, 23.620000000000005, 121.53999999999999, 10.39, 113.13, "C", -2.8400000000000034, 104.72, -2.68, 67.64, 6.63, 41, "C", 15.94, 14.36, 34.42, 2.22, 59.3, 0.16, "C", 84.17999999999999, -1.9000000000000001, 115.52, 16.16, 119.55, 35.62, "Z"].join(" "),
    fill: "#de4826",
    width: 120,
    height: 117
  },
  2: {
    // d: "M100.34,22.54c14,17,25.49,48.74,9,65s-47.54,31.34-59,13c-25-40-47-44-50-60-2.77-14.78,11.58-30.17,39-38C67.34-5.46,87.23,6.62,100.34,22.54Z",

    // from https://app.svgator.com
    d: ["M", 113.964936, 47.836621, "C", 121.214936, 67.44434899999993, 119.68376699999999, 86.270486, 89.828767, 101.506849, "C", 44.610131, 123.98184900000001, 52.39923199999997, 90.642591, 26.510391, 70.438894, "C", 13.203908222252842, 58.73843363165005, -13.181738906170649, 42.90992192429701, 8.635391, 19.17753, "C", 26.218118, 1.8443479999999999, 59.096299, -6.535197, 82.203572, 7.063894, "C", 100.14039099999998, 18.299349000000003, 106.98039, 28.819803000000007, 113.964936, 47.836621, "Z"].join(" "),
    fill: "#3ac1cc",
    width: 118,
    height: 109
  },
  3: {
    // d: "M118.05,11.15C134.55,23.51,112.47,51.77,96,68s-59.19,43.19-78.05,32.25S-1.1,75,.76,59.09s7.26-39,33-51.37S101.56-1.21,118.05,11.15Z",

    // from https://app.svgator.com
    d: ["M", 114.776987, 45.620082, "C", 106.890001, 57.80771899999993, 97.72975799999999, 67.85372100000001, 79.440639, 81.324201, "C", 49.88038700000001, 101.39190400000001, 25.85631999999997, 111.436956, 8.80137, 93.196347, "C", -7.122562777747156, 73.97071863165004, 1.577363093829351, 38.942691924297, 12.956621, 24.634703, "C", 26.831294000000003, 4.793131999999998, 59.075933000000006, -4.070628, 95.037054, 2.104981, "C", 120.40641399999998, 6.774901000000001, 133.11736199999999, 17.248970000000003, 114.776987, 45.620082, "Z"].join(" "),
    fill: "#f6bd17",
    width: 125,
    height: 104
  }
};
const copies = {
  topicals: {
    benefit_1: {
      title: "Provides essential nutrients to rejuvenate dry skin",
      content: "CBD topicals offer a perfect balance of powerful nutrients that generate moisture and provide nourishment to dry skin, unlike traditional creams."
    },
    benefit_2: {
      title: "Anti-inflammatory properties aid in pain reduction",
      content: "Highly regarded for its anti-inflammatory and healing benefits, CBD takes pain management a step further by absorbing directly into the affected area and providing instant relief on seasoned joints and muscles."
    },
    benefit_3: {
      title: "Antioxidants reduce signs of aging skin, eczema, and acne",
      content: "Well known for its antioxidant properties, CBD actively defends skin cells against free radicals in the body - promoting healthy, ageless skin."
    }
  },
  others: {
    benefit_1: {
      title: "Lift mood and increase cognition",
      content: "Regular use enhances cognition, helping you make sound decisions. Anti-anxiety properties work to relieve OCD & other anxious behaviors by restoring balance to the endocannabinoid system."
    },
    benefit_2: {
      title: "Help counter everyday stress",
      content: "Supports the body systems that regulate your responses to stressful stimuli. By checking your blood pressure, providing hormonal balance & adrenal support, regular use may lead to a more peaceful headspace."
    },
    benefit_3: {
      title: "Support recovery from exercise",
      content: "Anti-inflammatory properties prevent joint injury during exercise and reduce pain from swelling. Cannabinoids are known to have antispasmodic properties, which work to reduce the frequency and duration of muscle spasms. Regular use may also speed up the body’s natural recovery time."
    }
  }
};
const toggleTextDuration = 1000;
const toggleDurHalf = Math.round(toggleTextDuration / 2);

class SvgBlob extends Component {
  render () {
    return (
      <svg id="svg-benefits-blob"
        width={blobStates[1].width}
        height={blobStates[1].height}
        style={{
          width: blobStates[1].width,
          height: blobStates[1].height
        }}>
        <path
          d={blobStates[1].d}
          fill={blobStates[1].fill}
          fillRule="evenodd"
          className="svg-benefits-blob"
        />
      </svg>
    );
  }
}

export default class ProductBenefits extends Component {

  constructor (props) {
    super(props);
    this.state = {
      activeNum: 1
    };
  }

  render () {
    let benefitsCopy = copies.others;
    benefitsCopy = this.props.product.slug.indexOf("topicals") !== -1 ? copies.topicals : copies.others;
    return (
      <Fragment>
        <div id="product-benefits">
          <section className="section">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-5 has-text-centered is-relative">
                  <h3 className="has-text-weight-bold">
                    <Highlightable>What CBD can do</Highlightable>
                  </h3>
                  <h1>Your daily dose of well-being</h1>

                </div>
              </div>
              <div className="columns">
                <div className="column is-4">
                  <div className="svg-effects-container is-relative">
                    <SvgBlob />
                    <img id="benefit-1-image" className="benefit-image is-active" src={require("../../images/products/cognitive-mood.svg")} style={{ opacity: 1 }} alt="" />
                  </div>
                  <div id="benefit-1" className="benefit-text is-active has-text-centered" style={{ opacity: 1 }}>
                    <h3 className="has-text-danger">{benefitsCopy.benefit_1.title}</h3>
                    <p style={{marginTop:"10px"}}>{benefitsCopy.benefit_1.content}</p>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="svg-effects-container is-relative">
                  <svg id="svg-benefits-blob"
                    width={blobStates[2].width}
                    height={blobStates[2].height}
                    style={{
                      width: blobStates[2].width,
                      height: blobStates[2].height
                    }}>
                    <path
                      d={blobStates[2].d}
                      fill={blobStates[2].fill}
                      fillRule="evenodd"
                      className="svg-benefits-blob"
                    />
                  </svg>
                  <img id="benefit-2-image" className="benefit-image is-active" src={require("../../images/products/exercise.svg")} style={{ opacity: 1 }} alt="" />
                  </div>
                  <div id="benefit-2" className="benefit-text is-active has-text-centered" style={{ opacity: 1 }}>
                    <h3 className="has-text-primary">{benefitsCopy.benefit_2.title}</h3>
                    <p style={{marginTop:"10px"}}>{benefitsCopy.benefit_2.content}</p>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="svg-effects-container is-relative">
                  <svg id="svg-benefits-blob"
                    width={blobStates[3].width}
                    height={blobStates[3].height}
                    style={{
                      width: blobStates[3].width,
                      height: blobStates[3].height
                    }}>
                    <path
                      d={blobStates[3].d}
                      fill={blobStates[3].fill}
                      fillRule="evenodd"
                      className="svg-benefits-blob"
                    />
                  </svg>
                  <img id="benefit-3-image" className="benefit-image is-active" src={require("../../images/products/anxiety-depression.svg")} style={{ opacity: 1 }} alt="" />
                  </div>
                  <div id="benefit-3" className="benefit-text is-active has-text-centered" style={{ opacity: 1 }}>
                    <h3 className="has-text-warning">{benefitsCopy.benefit_3.title}</h3>
                    <p style={{marginTop:"10px"}}>{benefitsCopy.benefit_3.content}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="checkout-footer-gap" />
        </div>
      </Fragment>
    );
  }

  showBenefit = (num) => {
    // check already active
    if (num === this.state.activeNum) return;

    this.setState({ activeNum: num });

    const productBenefits = document.getElementById("product-benefits");
    const benefitImages = productBenefits.querySelectorAll(".benefit-image");
    const benefitTexts = productBenefits.querySelectorAll(".benefit-text");
    const svgBenefitsBlob = productBenefits.querySelector("svg#svg-benefits-blob");
    const svgBenefitsPath = svgBenefitsBlob.querySelector("path.svg-benefits-blob");

    // remove prev animations
    anime.remove([benefitImages, benefitTexts, svgBenefitsPath]);

    // toggle image
    const benefitImagesArr = Array.from(benefitImages);
    const currActiveImg = benefitImagesArr.find(e => e.classList.contains("is-active"));
    const nextActiveImg = benefitImagesArr.find(e => e.id === `benefit-${num}-image`);
    const fadeOutImg = anime({ targets: currActiveImg, opacity: 0, duration: toggleDurHalf, easing: "linear" });
    fadeOutImg.finished.then(() => {
      currActiveImg.classList.remove("is-active");
      nextActiveImg.classList.add("is-active");
      anime({ targets: nextActiveImg, opacity: 1, duration: toggleDurHalf, easing: "linear" });
    });

    // toggle text
    const benefitTextsArr = Array.from(benefitTexts);
    const currActiveText = benefitTextsArr.find(t => t.classList.contains("is-active"));
    const nextActiveText = benefitTextsArr.find(t => t.id === `benefit-${num}`);
    const fadeOutText = anime({ targets: currActiveText, opacity: 0, duration: toggleDurHalf, easing: "linear" });
    fadeOutText.finished.then(() => {
      currActiveText.classList.remove("is-active");
      nextActiveText.classList.add("is-active");
      anime({ targets: nextActiveText, opacity: 1, duration: toggleDurHalf, easing: "linear" });
    });

    // change svg size
    svgBenefitsBlob.setAttribute("width", blobStates[num].width);
    svgBenefitsBlob.setAttribute("height", blobStates[num].height);
    svgBenefitsBlob.style.width = `${blobStates[num].width}px`;
    svgBenefitsBlob.style.height = `${blobStates[num].height}px`;

    // animate svg path
    anime({
      targets: svgBenefitsPath,
      d: [{ value: blobStates[num].d }],
      fill: blobStates[num].fill,
      duration: 1000,
      easing: "easeOutElastic"
    });
  };

}