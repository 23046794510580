import React, { Component } from "react";
import AnimatedLines from "../animatedLines";
import Accordion from "../accordion";
import { Link } from "gatsby";

export default class FaqSection extends Component {
  render () {
    const commonFAQs = this.props.commonFAQs;

    return (
      <section className="section is-medium is-relative is-clipped is-overflow-hidden" style={{
        // paddingTop: "150px",
        // paddingBottom: "150px",
        // backgroundImage: `url(${Lines09})`,
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "left"
      }}>
        <AnimatedLines className="lines-03" style={{
          position: "absolute",
          top: "10%",
          left: "-30%",
          width: "45%",
          zIndex: "100",
          transform: "scale(1) rotate(80deg)"
        }} />
        <div className="container">
          <div className="columns">
            <div className="column is-4">
              <figure className="image is-1by1">
                <img src="https://reasontosmile.wpengine.com/wp-content/uploads/2019/05/Screen-Shot-2019-10-19-at-9.38.14-AM.png" alt="" />
              </figure>
            </div>
            <div className="column">
            </div>
            <div className="column is-7">
              <h2>Curious? Ask us anything</h2>
              <p style={{marginTop:"10px"}}>But first, check out a selection of common Q&As, below</p>
              <br/>
              <Accordion data={commonFAQs}
                onItemOpened={this.accordionHeightChanged}
                onItemClosed={this.accordionHeightChanged} />
              <br /><br />
              <Link to="/faq">
                <button className="button is-secondary">LEARN MORE ON FAQ PAGE</button>
              </Link>
            </div>
          </div>
        </div>

      </section>
    );
  }

  accordionHeightChanged = () => {
    this.props.accordionHeightChanged && this.props.accordionHeightChanged();
  };
}
