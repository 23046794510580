import React, { Component } from "react";
import CustomerReviews from "./customer-reviews";
import Highlightable from "../../highlightable";
export default class CustomerReviewsSection extends Component {

  render () {
    const {
        customerReviewsSummary,
        customerReviews,
        customerReviewsPagination,
        reviewsLoading,
        avg_rating,
        loadMoreReviewClick,
        writeReviewClick
      } = this.props;
    return (
        <section className="section" id="review">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered is-relative">
                  <h3 className="has-text-weight-bold">
                    <Highlightable>Customer Reviews</Highlightable>
                  </h3>
                  <h1>What are people saying about Smile</h1>
                </div>
              </div>
                <CustomerReviews
                            reviews={customerReviews}
                            summary={customerReviewsSummary}
                            avg_rating={avg_rating}
                            pagination={customerReviewsPagination}
                            reviewsLoading={reviewsLoading}
                            loadMoreReviewClick={loadMoreReviewClick}
                            writeReviewClick={writeReviewClick} />
                <button type="button" className="button btn-write-review is-transparent is-uppercase is-secondary"
                onClick={this.props.writeReviewClick}>Write a review
                </button>
            </div>
        </section>
    );
  }

}