import React, { Component, Fragment } from "react";
import Highlightable from "../highlightable";
import anime from "animejs";
import Slider from "react-slick";
import SvgFloatingBreezyBlob from "./svgFloatingBreezyBlob";
import SvgFloatingSunnyBlob from "./svgFloatingSunnyBlob";
import "./WhyWereDifferentSection.scss";
import _get from "lodash.get";

export default class WhyWereDifferentSection extends Component {

  constructor (props) {
    super(props);
    this.state = {
      activeNum: 1,
      autoplay: false
    };
  }

  showText = (num) => {
    // check already active
    if (num === this.state.activeNum) return;

    this.setState({ activeNum: num });

    const whyWereDifferent = document.getElementById("why-were-different");
    const texts = whyWereDifferent.querySelectorAll(".wwd-text");

    // remove prev animations
    anime.remove(texts);

    // toggle text
    const textsArr = Array.from(texts);
    const currActiveText = textsArr.find(t => t.classList.contains("is-active"));
    const nextActiveText = textsArr.find(t => t.id === `wwd-${num}`);
    const fadeOutText = anime({ targets: currActiveText, opacity: 0, duration: 250, easing: "linear" });
    fadeOutText.finished.then(() => {
      currActiveText.classList.remove("is-active");
      nextActiveText.classList.add("is-active");
      anime({ targets: nextActiveText, opacity: 1, duration: 250, easing: "linear" });
    });
  };

  render () {
    const product = this.props.product;

    // extract product image
    let productImageSrc = _get(product, "images[2].src");
    let effectImage, copy, color;
    switch (product.acf.effect) {
      case "Unwind":
        effectImage = "unwind"
        copy = "A formula that calms the mind & promotes peaceful sleep. We start with 100% organic CBD oil, then add 5-HTP & melatonin to relax your body and ease into a chiller headspace."
        break
      case "Energize":
        effectImage = "energize"
        copy = "Ingredients to keep you crisp, clear and in the zone. All the benefits of 100% organic CBD, plus vitamin B12 for increased energy, ginseng for mental clarity and 5-HTP for improved mood."
        break
      case "Balance":
        effectImage = "balance"
        copy = "Everything you need to help maintain a sense of balance. Our CBD gummies are the first of its kind! Not only are they vegan, they are also packed with 9 essential vitamins and minerals."
        break
      case "Rejuvenate":
        effectImage = "topicals"
        copy = "This iconic, fast-acting formula provides a renewed sense of moisture and instant relief from everyday aches-and-pains. With Rejuvenate, relief is in the palm of your hand."
        break   
      default:
        effectImage = "energize"
        copy = "Ingredients to keep you crisp, clear and in the zone. All the benefits of 100% organic CBD, plus vitamin B12 for increased energy, ginseng for mental clarity and 5-HTP for improved mood."
        break
    }
    switch (product.slug) {
      case "cbd-oil-mighty-mango":
        color = "#FF7F40";
        break;
      case "cbd-oil-mint-bliss":
        color = "#91CFAA";
        break;
      case "cbd-oil-french-vanilla":
        color = "#F1EBBF";
        break;
      case "cbd-oil-zesty-lemon":
        color = "#FADB00";
        break;
      case "cbd-topicals-pain-cream":
        color = "#4B6BA5";
        break;
      case "cbd-topicals-body-lotion":
        color = "#FF7F40";
        break;
      case "cbd-topicals-face-cream":
        color = "#33C1F5";
        break;
      default:
        color = "#C50C55";
        break;
    }
    return (
        <div id="why-were-different">
          <section className="section is-relative">
            <SvgFloatingBreezyBlob className="svg-breezy-blob" color={color}/>
            <img src={require(`../../images/theeffect/${effectImage}.svg`)} className={`svg-sunny-blob ${effectImage}`} alt=""/>
            <div className="container">
              <div className="columns is-vcentered">
                <div className="column has-text-centered">
                  <figure className="product-image-wrapper">
                    {productImageSrc && <img src={productImageSrc} className="product-image" alt={product.name} />}
                  </figure>
                </div>
                <div className="column is-5 text-column">
                  <h3 className="has-text-weight-bold">
                    <Highlightable>The effect</Highlightable>
                  </h3>
                    <div id="wwd-1" className="wwd-text">
                      <h1>{product.acf.effect}</h1>
                      <h4>{copy}</h4>
                    </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    );
  }

}
