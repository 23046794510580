import React, { Component } from "react";

export default class ReviewsSummaryRow extends Component {

  render () {

    const progress = this.props.count / this.props.total * 100;

    return (
      <div className="columns is-mobile reviews-summary-row is-vcentered is-gapless">
        <div className="column is-narrow">
          <p className="stars-count">{this.props.title}<label className="star-rating__star is-selected">★</label></p>
        </div>
        <div className="column">
          <div className="progress-wrapper">
            <div className="progress-container">
              <div className="progress" style={{ width: `${progress}%` }} />
            </div>
          </div>
        </div>
        <div className="column is-narrow">
          <p className="reviews-count">({this.props.count})</p>
        </div>
      </div>
    );
  }
}
