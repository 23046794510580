import React from "react";
import _get from "lodash.get";
import { Link } from "gatsby";

const Ingredient = (props) => {

  // const imageSrc = require(`../images/ingredients/${props.imageName}.png`)

  const ingredient = props.ingredient;
  const imageSrc = _get(ingredient, "featured_media.source_url");
  const imageAlt = _get(ingredient, "featured_media.alt_text") || ingredient.title || "";

  return (
    <div className="has-text-centered is-block">
      <Link to={`/ingredients/${ingredient.slug}`} style={{ color: "inherit" }}>
        <figure className="image is-square">
          <img src={imageSrc} alt={imageAlt} />
        </figure>
        <h4>{ingredient.title}</h4>
      </Link>
    </div>
  );

};

export default Ingredient;
