import React from "react";
import SelectDropdown from "../../components/form/select-dropdown";
import SelectDropdownUp from "../../components/form/select-dropdown-up";

const FlavorSelect = (props) => {
  let flavors;
  switch (props.selectedOption) {
    case "cbd-vegan-gummies-passion-fruit-300mg":
    case "cbd-vegan-gummies-acai-blast-300mg":
    case "cbd-vegan-gummies-fresh-watermelon-300mg":
      flavors = [
        { value: "cbd-vegan-gummies-passion-fruit-300mg", color: "passion-fruit-balance.svg", tag: "PASSION FRUIT", divider: " | ", subTag: "BALANCE" },
        { value: "cbd-vegan-gummies-acai-blast-300mg", color: "acai-blast.svg", tag: "ACAI BLAST", divider: " | ", subTag: "BALANCE" },
        { value: "cbd-vegan-gummies-fresh-watermelon-300mg", color: "fresh-watermelon.svg", tag: "FRESH WATERMELON", divider: " | ", subTag: "BALANCE" }
      ];
      break;
    case "cbd-topicals-pain-cream":
    case "cbd-topicals-body-lotion":
    case "cbd-topicals-face-cream":
      flavors = [
        { value: "cbd-topicals-pain-cream", color: "menthol-rejuvenate.svg", tag: "PAIN", divider: " | ", subTag: "REJUVENATE"},
        { value: "cbd-topicals-body-lotion", color: "cocoa-butter-rejuvenate.svg", tag: "BODY", divider: " | ", subTag: "REJUVENATE" },
        { value: "cbd-topicals-face-cream", color: "argan-oil-rejuvenate.svg", tag: "FACE", divider: " | ", subTag: "REJUVENATE" }
      ];
      break;
    default:
      flavors = [
        { value: "cbd-oil-mighty-mango", color: "mighty-mango-energize.svg", tag: "MIGHTY MANGO", divider: " | ", subTag: "ENERGIZE" },
        { value: "cbd-oil-zesty-lemon", color: "zesty-lemon-energize.png", tag: "ZESTY LEMON", divider: " | ", subTag: "ENERGIZE" },
        { value: "cbd-oil-mint-bliss", color: "mint-bliss-unwind.svg", tag: "MINT BLISS", divider: " | ", subTag: "UNWIND" },
        { value: "cbd-oil-french-vanilla", color: "french-vanilla-unwind.svg", tag: "FRENCH VANILLA", divider: " | ", subTag: "UNWIND" }
      ];
      break;
    }
  const selectedFlavors = flavors.find( flavor => flavor.value === props.selectedOption);
  const restFlavors = flavors.filter( flavor => flavor.value !== props.selectedOption);
  return (
    (typeof props.isUp != "undefined" && props.isUp) ?
    <SelectDropdownUp selectedOption={selectedFlavors} restOptions={restFlavors} type={"color"} onChange={props.onChange}/>
    :
    <SelectDropdown selectedOption={selectedFlavors} restOptions={restFlavors} type={"color"} onChange={props.onChange} hasWhite={props.hasWhite}/>

  );

};

export default FlavorSelect;
