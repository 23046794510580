import React from "react";
import QuantityButton from "../form/quantityButton";
import AddToCartButton from "../form/add-to-cart-button";
import FlavorSelect from "../form/flavorSelect";
import SelectProductDosage from "./select-product-dosage";
import { renderTags } from "../../utilities/product";
import PropTypes from "prop-types";
import SelectProductVariant from "./select-product-variant";

export default function CheckoutFooter (
  {
    product,
    quantity,
    handleAmountToggle,
    productDosage,
    handleAddToBag,
    productVariant,
    handleProductVariantSelect,
    handleFlavorChange,
    handleDosageChange
  }
) {

  let name = product.name;
  if (product.slug.indexOf("vegan-gummies") !== -1) {
    name = "<span class='is-hidden-tablet'>CBD Gummies</span>"
      + "<span class='is-hidden-mobile'>CBD Vegan Gummies</span>";
  }else{
  }
  switch (product.slug) {
    case "cbd-vegan-gummies-passion-fruit-300mg":
    case "cbd-vegan-gummies-acai-blast-300mg":
    case "cbd-vegan-gummies-fresh-watermelon-300mg":
      name = "<span class='is-hidden-tablet'>CBD Gummies</span>"
      + "<span class='is-hidden-mobile'>CBD Vegan Gummies</span>";
      break;
    case "cbd-topicals-pain-cream":
    case "cbd-topicals-body-lotion":
    case "cbd-topicals-face-cream":
      name = "<span>Topicals</span>"
      break;
    default:
      name = "<span>CBD Oil</span>"
      break;
  }
  let tags = renderTags(product, "", tag => {
    return `<span class="f-tag">${tag}</span>`;
  });
  const dosage = product.acf.dosage;
  if (dosage) {
    tags += `<span class="f-tag dosage">${dosage}</span>`;
  }
  const productType = product.acf.product_type;
  const flavor = product.acf.flavor;
  const effect = product.acf.effect;

  return (
    <footer id="checkout-footer" className="checkout-footer has-yellow-background is-fixed-bottom hide">
      <div className="container">
        <div className="columns is-vcentered is-mobile">

          <div className="column is-3-desktop is-size-4-mobile has-text-white">
          <img src={require(`../../images/menu/${product.slug.indexOf("vegan-gummies") !== -1 ? "gummies.svg":(product.slug.indexOf("topicals") !== -1 ? "Topicals.svg":"tinctures.svg")}`)} className="is-inline is-vertical-align checkout-footer-icons is-hidden-mobile" alt=""/>
          <h3 className="f-title" dangerouslySetInnerHTML={{ __html: name }} />
            <p className="has-text-weight-bold is-uppercase is-hidden-desktop" dangerouslySetInnerHTML={{ __html: tags }} />
            {/*<h3 className="is-uppercase">{productType}</h3>*/}
            {/*<p className="has-text-weight-bold is-uppercase">{flavor} | {effect} | {dosage}</p>*/}
          </div>

          <div className="column is-narrow has-text-centered">
            <div className="columns is-gapless is-vcentered">
              <div className="column is-narrow flavor-select is-hidden-mobile">
                <FlavorSelect selectedOption={product.slug} onChange={handleFlavorChange} isUp={true}/>
              </div>
              <div className="column is-narrow product-dosage-select is-hidden-mobile">
                <SelectProductDosage selectedOption={product.slug} isUp={true} onChange={handleDosageChange}/>
              </div>
              <div className="column is-hidden-mobile product-variant-select">
                <SelectProductVariant
                  product={product}
                  value={productVariant}
                  onChange={handleProductVariantSelect}
                  productDosage={productDosage}
                  isUp={true} />
              </div>
              <div className="column is-narrow quantity-select">
                <div className="quantity-buttons-container">
                  <AddToCartButton onClick={handleAddToBag} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </footer>
  );
}

CheckoutFooter.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  handleAmountToggle: PropTypes.func.isRequired,
  handleAddToBag: PropTypes.func.isRequired,
  productVariant: PropTypes.string.isRequired,
  handleProductVariantSelect: PropTypes.func.isRequired
};
