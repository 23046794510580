import React, { Component } from "react";
import { isTestProduct, isCopyProduct } from "../../lib/helpers";
import RelatedProduct from "../relatedProduct";

export default class RelatedProductSection extends Component {
  render () {
    const product = this.props.product;
    
    const related_products = product.related_products.filter(product => !isTestProduct(product) && !isCopyProduct(product) && product.status === "publish" && product.type === "grouped" && product.tags.find(c => c.name === "CBD") && product.stock_status === "instock")
    if (related_products && related_products[0]) {
      const related_product = related_products[0];
      return (
        <RelatedProduct
          name={related_product.acf.product_type}
          description={related_product.description}
          images={related_product.images}
          price={related_product.price}
          slug={related_product.slug}
        />
      );
    }

    return null;
  }
}
