import React, { Component } from "react";
import AnimatedLines from "../animatedLines";
import { isBrowser } from "react-device-detect";
import Ingredient from "../ingredient";

export default class MoreThanSection extends Component {

  render () {
    return (
      <section className="section is-relative is-overflow-hidden">
        <AnimatedLines className="lines-01" style={{
          position: "absolute",
          top: "0",
          left: isBrowser ? "-10%" : "-60%",
          width: "509px",
          zIndex: "100",
          transform: "scale(1) rotate(-45deg)",
          pointerEvents: "none"
        }} />
        <AnimatedLines className="lines-02" style={{
          position: "absolute",
          bottom: "15%",
          right: isBrowser ? "-5%" : "-110%",
          width: "747px",
          zIndex: "100",
          transform: "scale(1.25) rotate(45deg)",
          pointerEvents: "none"
        }} />

        <div className="container">
          <div className="columns is-centered">
            <div className="column is-6 has-text-centered">
              <h1>More than just pure, organic CBD</h1>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-5 has-text-centered">
              <h4 style={{ minHeight: 75 }}>Organic CBD isolate joins forces with vitamin B12 for increased energy, ginseng for mental clarity and 5-HTP for improved mood.</h4>
            </div>

          </div>
          <div className="columns is-centered is-multiline is-mobile">

            {this.props.ingredients.map(ingredient =>
              <div key={ingredient.id} className="column is-3-desktop is-4-tablet is-6-mobile">
                <Ingredient ingredient={ingredient} />
              </div>
            )}

            {/*
            <div className="column is-4-desktop is-4-tablet is-6-mobile">
              <Ingredient imageName="cbd-isolate" name="CBD Isolate" />
            </div>
            <div className="column is-4-desktop is-4-tablet is-6-mobile">
              <Ingredient imageName="mct-coconut-oil" name="MCT Coconut Oil" />
            </div>
            <div className="column is-4-desktop is-4-tablet is-6-mobile">
              <Ingredient imageName="hemp-seed-oil" name="Hemp Seed Oil" />
            </div>
            <div className="column is-4-desktop is-4-tablet is-6-mobile">
              <Ingredient imageName="5-htp" name="5-HTP" />
            </div>
            <div className="column is-4-desktop is-4-tablet is-6-mobile">
              <Ingredient imageName="b12" name="Vitamin B12" />
            </div>
            <div className="column is-4-desktop is-4-tablet is-6-mobile">
              <Ingredient imageName="ginseng" name="Ginseng Extract" />
            </div>
            <div className="column is-4-desktop is-4-tablet is-6-mobile">
              <Ingredient imageName="omega-3" name="Omega-3" />
            </div>
            <div className="column is-4-desktop is-4-tablet is-6-mobile">
              <Ingredient imageName="omega-6" name="Omega-6" />
            </div>
            <div className="column is-4-desktop is-4-tablet is-6-mobile">
              <Ingredient imageName="omega-9" name="Omega-9" />
            </div>
            */}

          </div>
        </div>

      </section>
    );
  }
}
