import React from "react";
import Highlightable from "../../highlightable";
import IngredientsAmountsTable from "./ingredients-amounts-table";
import "./product-details.scss";
import _get from "lodash.get";
import { renderTags } from "../../../utilities/product";
import parseProductAcf from "./parse-product-acf";

const ProductDetails = ({ product, productDosage }) => {

  const productName = product.name;
  const imageUrl = _get(product, "images[0].src");
  const imageAlt = _get(product, "images[0].alt") || productName || "";
  const tags = renderTags(product);
  const acf = parseProductAcf(product);
  
  return (
    <div className="columns product-details">
      <div className="column is-7">
        <div className="columns is-vcentered">
          <div className="column is-6">
            <figure className="product-row-image image is-square product-details--image has-text-centered">
              {imageUrl && <img  src={imageUrl} className="is-inline" alt={imageAlt} />}
            </figure>
          </div>
          <div className="column is-6">
            <h2>{productName}</h2>
            <h3 className="has-text-weight-bold main_ingredients">
              <Highlightable>{acf.main_ingredients}</Highlightable>
            </h3>
            <p>{tags}</p>
            <p className="size">{acf.size}</p>
            <br />
            <p>Dietary Supplement</p>
            {"1000" === productDosage && ("Energize" === acf.effect || "Unwind" === acf.effect) ?
            <p className="dosage">1000 mg</p>
            :
            <p className="dosage">{acf.dosage}</p>
            }
          </div>
        </div>
        <div className="product-details--suggested-use" dangerouslySetInnerHTML={{ __html: acf.suggested_use }} />
      </div>

      <div className="column is-5">
        { "Rejuvenate" !== acf.effect ?
        <div className="product-details--supplement-facts">
          <div dangerouslySetInnerHTML={{ __html: acf.supplement_facts_before }} />
          <IngredientsAmountsTable data={acf.ingredients_amounts} effect={acf.effect} productDosage={productDosage}/>
          <div dangerouslySetInnerHTML={{ __html: acf.supplement_facts_after }} />
        </div>
        :
        false
        }
      </div>
    </div>
  );

};

export default ProductDetails;
