import React, { Fragment } from "react";

const defaultData = require("json5-loader!./ingredients-amounts.json5");

const SupplementFactsRow = (props) => {
  return (
    <div className="columns is-mobile is-gapless is-marginless">
      <div className="column is-7">
        <p>{props.name}</p>
      </div>
      <div className="column is-3">
      {"1000" === props.productDosage && ("Energize" === props.effect || "Unwind" === props.effect) &&  "Domestic CBD Extract"===props.name ?
        <p>16.7mg</p>
        :
        <p>{props.amount}</p>
      }
      </div>
      <div className="column has-text-right">
        <p>{props.dv}</p>
      </div>
    </div>
  );
};

export default function IngredientsAmountsTable ({ data = defaultData, effect, productDosage }) {

  return (
    <Fragment>
      <hr className="bold" />
      <p className="is-inline has-text-weight-bold">Amount per serving</p>
      <p className="has-text-weight-bold has-text-align-right is-pulled-right is-inline ">%DV</p>
      <hr className="medium" />
      {data.rows.map((row, i) => {
        return (
          <Fragment key={i}>
            <SupplementFactsRow name={row.name} amount={row.amount} dv={row.daily_value} effect={effect} productDosage={productDosage}/>
            <hr className={`has-black-background ${row.hr_after || "normal"}`} />
          </Fragment>
        );
      })}
    </Fragment>
  );
}
