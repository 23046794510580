import React, { Fragment } from "react";
import Rating from "react-rating";
import ReviewsSummary from "./reviews-summary";
import "./customer-reviews.scss";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import Pagination from "react-js-pagination";
import _get from "lodash.get";

class CustomerReview extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      opened: false
    };
  }

  render () {
    const review = this.props.review;
    const date = format(parseISO(review.created_at), "MM/dd/yyyy");
    return (
      <li className="customer-review__item">
        <div className="customer-review">
          <header className="customer-review__header">
            <div className="columns is-mobile is-multiline">
              <div className="column is-6-mobile">
                <p className="has-text-weight-bold">{review.user.display_name}</p>
                {review.verified_buyer &&
                <Fragment>
                  <p className="has-text-weight-normal has-text-grey reviewer-type-tooltip-handler"><i className="far fa-check" style={{marginRight:'5px'}}></i>Verified Buyer</p>
                  <div className="reviewer-type-tooltip">
                    <div className="tooltip-title">Whats is a <span className="has-text-success">Verified Buyer</span></div>
                    <div>
                      A Verified Buyer is a user who has purchased<br />
                      the reviewed product through our store.
                    </div>
                  </div>
                </Fragment>
                }
              </div>
              <div className="column is-narrow is-6-mobile">
                <p>{date}</p>
              </div>
            </div>
          </header>
          <div className="customer-review__line">
            <Rating
              className="star-rating readonly"
              readonly={true}
              initialRating={review.score}
              emptySymbol={<label className="star-rating__star">★</label>}
              fullSymbol={<label className="star-rating__star is-selected">★</label>}
            />
          </div>
          <h4 className="has-text-primary is-inline" dangerouslySetInnerHTML={{ __html: review.title }} />
          <div className="customer-review__inner">
            <div className="customer-review__content">
              <p className="customer-review__paragraph" dangerouslySetInnerHTML={{ __html: review.content }} />
            </div>
          </div>
        </div>
        { typeof review.comment !== "undefined" ?
              <div className="customer-review" style={{paddingLeft:"20px",marginTop:"20px"}}>
              <header className="customer-review__header">
                <div className="columns">
                  <div className="column">
                    <p className="has-text-weight-bold">Team Smile</p>
                  </div>
                </div>
              </header>
              <div className="customer-review__inner">
                <div className="customer-review__content">
                  <p className="customer-review__paragraph" dangerouslySetInnerHTML={{ __html: review.comment.content }} />
                </div>
              </div>
            </div>
              :
              false
        }
      </li>
    );
  }
}

class CustomerReviews extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }
  handlePageChange = (pageNumber) => {
    this.setState({activePage: pageNumber});
  }
  render () {
  /**
   * pagination:
   * {
   *   page: int
   *   per_page: int
   *   total: int
   * }
   */
  const pagination = this.props.pagination || {};
  const page = _get(pagination, "page", 1);
  const per_page = _get(pagination, "per_page", 10);
  const total = _get(pagination, "total", 0);
  const hasMoreReviews = page * per_page < total;

  const indexOfLastItem = this.state.activePage * 5;
  const indexOfFirstItem = indexOfLastItem - 5;

  return (
    <div className="columns is-reversed-mobile product-customer-reviews" style={{ minHeight: "500px" }}>
      <div className="column is-6">
        <ul className="customer-reviews">
          {this.props.reviews.slice(indexOfFirstItem, indexOfLastItem).map(review => {
            return (
              <CustomerReview key={review.id} review={review} />
            );
          })}
        </ul>
        <Pagination
          hideFirstLastPages
          activePage={this.state.activePage}
          pageRangeDisplayed={10}
          itemsCountPerPage={5}
          totalItemsCount={this.props.reviews.length}
          onChange={this.handlePageChange}
          itemClass={"pagination-item"}
          itemClassPrev={"item-prev"}
          itemClassNext={"item-next"}
          prevPageText={"<"}
          nextPageText={">"}
        />
        {/*hasMoreReviews &&
        <button onClick={this.props.loadMoreReviewClick}
          className={`button is-primary is-outlined is-uppercase is-fullwidth-mobile button-load-more-reviews ${this.props.reviewsLoading ? "is-loading" : ""}`}>
          Load more reviews
        </button>
        */}

      </div>
      <div className="column is-5 is-offset-1 is-relative is-overflow-visible reviews-summary-section">
        <ReviewsSummary summary={this.props.summary} writeReviewClick={this.props.writeReviewClick} avg_rating={this.props.avg_rating}/>
      </div>

    </div>
  );
}
}

export default CustomerReviews;
