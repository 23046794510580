import React, { Component/*, useRef*/, createRef } from "react";
import { graphql, navigate } from "gatsby";
import auth from "../lib/auth";
import PropTypes from "prop-types";
import SEO from "../components/seo";
import LayoutFullpage from "../components/layout-fullpage";
import Header from "../components/header";
import Footer from "../components/footer";
import { commonFAQs } from "../lib/faq-content";
import Helmet from "react-helmet";

// import Lines09 from "../images/lines_09.svg"
import ProductHero from "../components/product/ProductHero";
import ProductBenefits from "../components/product/ProductBenefits";
import MoreThanSection from "../components/product/MoreThanSection";
import BenefitsSection from "../components/product/BenefitsSection";
import AccordionSection from "../components/product/AccordionSection";
import ReviewModal from "../components/product/reviews/review-modal";
import NotifyMeModal from "../components/product/notify-me-modal";
import WhyWereDifferentSection from "../components/product/WhyWereDifferentSection";
import TestimonialsSection from "../components/product/TestimonialsSection";
import FaqSection from "../components/product/FaqSection";
import RelatedProductSection from "../components/product/RelatedProductSection";
import CheckoutFooter from "../components/product/checkoutFooter";
import CustomerReviewsSection from "../components/product/reviews/customer-reviews-section";
// import anime from "animejs";
import { addToCart, readLocalCart } from "../lib/cart";
import update from "immutability-helper";
import _get from "lodash.get";
import yotpo from "../lib/yotpo";

const reviews_per_page = 10;

// fullpage
import FacebookPixel from "../lib/tracking/facebook-pixel";
import Klayvio from "../lib/tracking/klayvio";

const fullpageScrollingSpeed = 1000;

const parseReviewsData = reviewsData => ({
  reviewsSummary: {
    total_reviews: _get(reviewsData, "bottomline.total_review", 0) || 0,
    starred_5: _get(reviewsData, "bottomline.star_distribution.5", 0) || 0,
    starred_4: _get(reviewsData, "bottomline.star_distribution.4", 0) || 0,
    starred_3: _get(reviewsData, "bottomline.star_distribution.3", 0) || 0,
    starred_2: _get(reviewsData, "bottomline.star_distribution.2", 0) || 0,
    starred_1: _get(reviewsData, "bottomline.star_distribution.1", 0) || 0,
    avg_rating: _get(reviewsData, "bottomline.average_score", 0) || 0
  },
  reviews: _get(reviewsData, "reviews", []),
  reviewsPagination: _get(reviewsData, "pagination")
});

class ProductTemplate extends Component {
  constructor (props) {
    super(props);

    const parsedReviewsData = parseReviewsData(this.props.reviewsData);
    var total_reviews = 0, total_rating = 0;
    this.props.productsBottomLine.forEach(bottomLine => {
        total_reviews = total_reviews + bottomLine.total_reviews;
        total_rating = total_rating + bottomLine.total_reviews * bottomLine.product_score;
      })
    const avg_rating = total_rating/total_reviews;

    this.state = {
      productVariant: "subscription", // subscription or simple (one-off)
      quantity: 1,
      productDosage: (this.props.data.product.slug.indexOf("vegan-gummies") !== -1 || this.props.data.product.slug.indexOf("face-cream") !== -1) ? "300" : "500",
      // reviews data
      reviewsSummary: parsedReviewsData.reviewsSummary,
      reviews: parsedReviewsData.reviews,
      avg_rating: avg_rating,
      reviewsPagination: parsedReviewsData.reviewsPagination,
      reviewsLoading: false,
      isCartModalOpen: false,
      newCartItem: null,
      localCart:{}
    };

    // refs
    this._header = createRef();
    this._productBenefits = createRef();
    this._wwdSection = createRef();
    this._reviewModal = createRef();
    this._notifyMeModal = createRef();
    this._accordionSection = createRef();
    this._accordionScrollSection = createRef();
  }

  componentDidMount () {
    // 1. Create a ref
    // const ref = useRef(document.getElementById("product-hero"));
    // // 2. Pass it to `useScrollState`
    // const scrollState = useScrollState(ref);
    //
    // // 3. Get the current scroll state.
    // let className;
    // if (!scrollState.isInViewport) {
    //   alert('in')
    // } else {
    //   alert('out')
    // }
    // anime({
    //   targets: '.product-header-blob',
    //   d: [
    //     {
    //       value: "M421.22,505.78c-102.46-12.65-123-10-272-111-140-94.89-178.53-171.71-128-278,29-61,71.53-74.28,128-91,60.89-18,123.53-33.08,175-22,79,17,236.21,186.74,245,260,5.19,43.19-20.45,76.17-33.5,127.5C526.64,427,546.72,521.28,421.22,505.78Z"
    //     },
    //     {
    //       value: "M464.1,567.46c-102.59-11.62-217-160.7-298-172-129-18-200-136-150-258,25.62-62.5,93.53-48.28,150-65,60.89-18,119.89-86.17,170-70,155,50,281.21,247.74,290,321,5.18,43.19-4.28,85.16-29,132C578.1,491.46,517.1,573.46,464.1,567.46Z"
    //     }
    //   ],
    //   easing: 'linear',
    //   autoplay: true,
    //   loop: true,
    //   duration: 5000
    // });

    // in gatsby-node.js loaded reviews for SSR, now load runtime
    yotpo.getGroupedProductReviews(this.props.data.product, {
      per_page: reviews_per_page,
      page: 1
    }).then(reviewsData => {
      const parsedReviewsData = parseReviewsData(reviewsData);
      this.setState({
        reviewsSummary: parsedReviewsData.reviewsSummary,
        reviews: parsedReviewsData.reviews,
        reviewsPagination: parsedReviewsData.reviewsPagination
      });
      Klayvio.viewProduct(this.props.data.product);
    });
    const subProducts = this.props.data.product.grouped_products_nodes || [];
    let product = subProducts.find(p => p.type === this.state.productVariant && p.slug.indexOf(this.state.productDosage) !== -1);
    if (typeof window.ga === "function") {
      window.ga('ec:addImpression', {
        'id': this.props.data.product.id,
        'name': this.props.data.product.name,
        'category': this.props.data.product.categories.map(function(elem){
          return elem.name;
        }).join('/'),
        'list': this.props.data.product.acf.product_type
      });
      window.ga('ec:addProduct', {
        'id': this.props.data.product.id,
        'name': this.props.data.product.name,
        'category': this.props.data.product.categories.map(function(elem){
          return elem.name;
        }).join('/')
      });
      window.ga('ec:setAction', 'detail');
    }
    
  }

  componentWillUnmount () {
    // Remove these elements from DOM: header#masthead, #product-benefits, #why-were-different, #checkout-footer
    // cause it was moved to body append by fullpage plugin
    document.querySelector("header#masthead").remove();
    document.getElementById("product-benefits").remove();
    document.getElementById("why-were-different").remove();
    document.getElementById("checkout-footer").remove();
    console.log("fixed elements removed");
  }

  hideCheckoutFooter = () => {
    const checkoutFooter = document.getElementById("checkout-footer");
    checkoutFooter && checkoutFooter.classList.add("hide");
  };
  showCheckoutFooter = () => {
    const checkoutFooter = document.getElementById("checkout-footer");
    checkoutFooter && checkoutFooter.classList.remove("hide");
  };
  handleDosageChange = (value) => {
    this.setState({productDosage:value})
  }
  handleFlavorChange = (value) => {
    if (typeof value === "object"){
      navigate("/products/"+value.target.value);
    }else{ 
      navigate("/products/"+value);
    }
  };
  handleProductVariantSelect = (productVariant) => {
    if (typeof productVariant === "object"){
      this.setState({ productVariant: productVariant.target.value });
    }else{
    this.setState({ productVariant: productVariant });
    }
    FacebookPixel.track( 'CustomizeProduct'); // todo also call this event when select dosage will be implemented
  };

  handleAmountToggle = (sign) => {
    if (sign === "+") {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      if (this.state.quantity === 0) return;
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  handleAddToCart = () => {
    const subProducts = this.props.data.product.grouped_products_nodes || [];

    // select sub product "subscription" or "simple"
    let product = subProducts.find(p => p.type === this.state.productVariant && p.slug.indexOf(this.state.productDosage) !== -1);

    // save one-off price for "subscription" product
    if (product.type === "subscription") {
      const oneOffProduct = subProducts.find(p => p.type === "simple" && p.slug.indexOf(this.state.productDosage) !== -1);
      const oneOffPrice = _get(oneOffProduct, "price");
      product = { ...product, oneOffPrice };
    }

    /*
     * this.props.data.product - is parent Grouped product
     * product - is child product (Subscription or One-time purchase)
     */
    const productDescription = this.props.data.product.description;
    const productUrl = window.location.href;
    const productCategories = this.props.data.product.categories;
    const p_slug = this.props.data.product.slug;

    const toCartProduct = {
      ...product,
      description: productDescription,
      url: productUrl,
      categories: productCategories,
      p_slug: p_slug
    };
    const localCart = readLocalCart();
    addToCart(localCart, toCartProduct, {
      product_id: product.id,
      quantity: this.state.quantity
    });

    this.addNewCartItem(localCart, toCartProduct);
    this.handleCartModalOption();
    FacebookPixel.track( 'AddToCart');
    if (typeof window.ga === "function") {
      window.ga('ec:addProduct', {
        'id': product.id,
        'name': product.name,
        'category': productCategories.map(function(elem){
          return elem.name;
        }).join('/'),
        'variant': product.type,
        'price': product.price,
        'quantity': 1
      });
      window.ga('ec:setAction', 'add');
      window.ga('send', 'event', 'UX', 'click', 'add to cart'); 
    }
  };

  onSectionLeave = (origin, destination, direction) => {

    // <editor-fold desc="toggle fixed header">
    if (origin.item.id === "product-section-first" && direction === "down") {
      // hide fixed header
      document.querySelector("header#masthead").style.transform = "translateY(-100vh)";
      this.showCheckoutFooter();
    } else if (destination.item.id === "product-section-first" && direction === "up") {
      // show fixed header
      document.querySelector("header#masthead").style.transform = "none";
      this.hideCheckoutFooter();
    }
    // </editor-fold>

  };

  afterSectionLoad = () => {
    //
  };

  accordionHeightChanged = () => {
    // wait accordion animation finish
    //setTimeout(fullpageApi.reBuild, 400); // transition duration + transition delay in accordion.scss
  };

  writeReviewClick = () => {
    if (auth.loggedIn) {
      this._reviewModal.current.open();
    } else {
      this._header.current.openLoginModal(true,() => {
        this._reviewModal.current.open();
      });
    }
  };

  notifyMeClick = () => {
    this._notifyMeModal.current.open();
  }
  loadMoreReviewClick = async (fullpageApi) => {
    this.setState({ reviewsLoading: true });

    try {

      // API call
      yotpo.getGroupedProductReviews(this.props.data.product, {
        per_page: this.state.reviewsPagination.per_page,
        page: this.state.reviewsPagination.page + 1
      }).then(reviewsData => {
        const parsedReviewsData = parseReviewsData(reviewsData);

        const existReviewsIds = this.state.reviews.map(r => r.id);
        const newReviews = parsedReviewsData.reviews.filter(r => !existReviewsIds.includes(r.id));
        const reviews = update(this.state.reviews, { $push: newReviews });
        const reviewsPagination = parsedReviewsData.reviewsPagination;

        this.setState({ reviews, reviewsPagination, reviewsLoading: false });

        setTimeout(fullpageApi.reBuild, 100);
      });

    } finally {
      this.setState({ reviewsLoading: false });
    }
  };

  afterReviewSent = (data) => {
    /* not auto prepend new review, cause confirmation process */
  };

  jumpToReviews = (fullpageApi) => {
    const sections = Array.from(document.getElementsByClassName("product-section"));
    const index = sections.findIndex(s => s.id === "product-section-details");
    if (~index) {
      fullpageApi.moveTo(index + 1);

      this._accordionSection.current._accordion.current.toggleItem(1);      
      setTimeout(() => {
        const iScrollInstance = document.querySelector("#product-section-details .fp-scrollable").fp_iscrollInstance;
        iScrollInstance.scrollToElement(".accordion-reviews", 0, 0, 0);
      }, fullpageScrollingSpeed);
      
    }
  };
  handleScrollTo = (elRef) => {
    const el = elRef.current ? elRef.current : elRef
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })

    //this._accordionSection.current._accordion.current.toggleItem(1);
  }
  handleCartModalClose = () => {
    this.setState({ isCartModalOpen: false })
  }
  handleCartModalOption = () => {
    this.setState({ isCartModalOpen: true })
  }
  addNewCartItem = (localCart, item) => {
    this.setState({ newCartItem: item, localCart: localCart })
  }

  render () {
    const product = this.props.data.product;
    let product_ingredients_ids = [];
    product_ingredients_ids = product.acf.ingredients;
    let ingredients = this.props.data.ingredients.nodes;
    ingredients.sort((a, b) => a.title.localeCompare(b.title));
    const product_ingredients = ingredients.filter(function(ingredient) {
      if (product_ingredients_ids) {
        return product_ingredients_ids.includes(ingredient.wordpress_id);
      }
    });
    const subscriptionProduct = (product.grouped_products_nodes || []).find(p => p.type === "subscription");
    const regularSubscriptionPrice = subscriptionProduct ? subscriptionProduct.regular_price : null;
    let ratingValue = 5;
    let ratingName = "Tyler Giroud";
    const filteredReviews = this.state.reviews.filter(r => r.verified_buyer && r.score === 5);
    if(filteredReviews.length > 0){
        ratingValue = filteredReviews[0].score;
        ratingName = filteredReviews[0].user.display_name
    }
    const sortedReviews = this.state.reviews.sort(function compare(a, b) {
      return 0.5 - Math.random();
    });
    const seoTitle = this.props.data.product.attributes.filter((attr) => attr.name === "SEOTitle")[0]?.options.join(' | ') || product.name
    const seoDesc = this.props.data.product.attributes.filter((attr) => attr.name === "SEODesc")[0]?.options.join() || undefined
    
    return (
      <div className="is-overflow-hidden">
        <Helmet>
          <script type="application/ld+json">{`
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "${subscriptionProduct.name}",
              "image": [
                "${_get(product, "images[0].src")}",
                "${_get(product, "images[1].src")}",
                "${_get(product, "images[2].src")}"
              ],
              "description": "${product.description.replace(/<\/?[^>]+(>|$)/g, "")}",
              "sku": "${subscriptionProduct.sku}",
              "mpn": "${subscriptionProduct.wordpress_id}",
              "brand": {
                "@type": "Brand",
                "name": "Reason To Smile"
              },
              "review": {
                "@type": "Review",
                "reviewRating": {
                  "@type": "Rating",
                  "ratingValue": "${ratingValue}",
                  "bestRating": "5"
                },
                "author": {
                  "@type": "Person",
                  "name": "${ratingName}"
                }
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "${this.state.avg_rating.toFixed(1)}",
                "reviewCount": "${this.state.reviewsSummary.total_reviews}"
              },
              "offers": {
                "@type": "Offer",
                "url": "https://reasontosmile.com/products/${product.slug}",
                "priceCurrency": "USD",
                "price": "${regularSubscriptionPrice}",
                "availability": "https://schema.org/InStock",
                "seller": {
                  "@type": "Organization",
                  "name": "Reason To Smile"
                }
              }
            }
          `}</script>
        </Helmet>
      <LayoutFullpage isCartModalOpen={this.state.isCartModalOpen} handleCartModalClose={this.handleCartModalClose} newCartItem={this.state.newCartItem} localCart={this.state.localCart}>
        <SEO title={seoTitle} description={seoDesc} />
          <div className="product-section has-yellow-background" id="product-section-first">
            <Header isAlternate={true} ref={this._header} />
            <ProductHero product={product}
              productVariant={this.state.productVariant}
              quantity={this.state.quantity}
              productDosage={this.state.productDosage}
              reviewsSummary={this.state.reviewsSummary}
              avg_rating={this.state.avg_rating}
              handleDosageChange={this.handleDosageChange}
              handleFlavorChange={this.handleFlavorChange}
              handleProductVariantSelect={this.handleProductVariantSelect}
              handleAmountToggle={this.handleAmountToggle}
              addToCart={this.handleAddToCart}
              onReviewsClick={() => { this.handleScrollTo(this._accordionScrollSection) }} 
              notifyMeClick={this.notifyMeClick} />
          </div>
           
          <div className="product-section fp-auto-height" id="product-section-details">
            <AccordionSection
                ref={this._accordionSection}
                product={product}
                productDosage={this.state.productDosage}
                accordionHeightChanged={() => this.accordionHeightChanged()} />
            <div ref={this._accordionScrollSection}>
              <CustomerReviewsSection 
                customerReviewsSummary={this.state.reviewsSummary}
                avg_rating={this.state.avg_rating}
                customerReviews={sortedReviews}
                customerReviewsPagination={this.state.reviewsPagination}
                reviewsLoading={this.state.reviewsLoading}
                loadMoreReviewClick={() => this.loadMoreReviewClick()}
                writeReviewClick={this.writeReviewClick}
              />
            </div>
            <WhyWereDifferentSection ref={this._wwdSection} product={product} />
            <ProductBenefits ref={this._productBenefits} product={product}/>
            <MoreThanSection ingredients={product_ingredients} />
            <BenefitsSection /> 
            <TestimonialsSection />
            <FaqSection commonFAQs={commonFAQs}/>
            <RelatedProductSection product={product} />
            
            <Footer />
          </div>
        <ReviewModal ref={this._reviewModal} product={product} afterSent={this.afterReviewSent} />
        <NotifyMeModal ref={this._notifyMeModal} />
        <CheckoutFooter
              product={product}
              productVariant={this.state.productVariant}
              productDosage={this.state.productDosage}
              handleProductVariantSelect={this.handleProductVariantSelect}
              handleDosageChange={this.handleDosageChange}
              quantity={this.state.quantity}
              handleAmountToggle={this.handleAmountToggle}
              handleAddToBag={this.handleAddToCart}
              handleFlavorChange={this.handleFlavorChange}
            />
      </LayoutFullpage>
      </div>
    );
  }
}

ProductTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
};

// export default ProductTemplate;

export default ({ data, pageContext }) => (
  <ProductTemplate data={data} reviewsData={pageContext.reviewsData} productsBottomLine={pageContext.productsBottomLine}/>
)

// noinspection JSUnusedGlobalSymbols
export const pageQuery = graphql`
    query($id: String!) {
        product: wcProducts(id: { eq: $id }) {
            id
            type
            wordpress_id
            name
            price
            slug
            stock_status
            attributes {
              name
              options
            }
            images {
                id
                alt
                src
            }
            categories {
                wordpress_id
                name
            }
            related_products {
                id
                name
                description
                price
                wordpress_id
                slug
                status
                type
                stock_status
                images {
                    id
                    src
                }
                tags {
                  name
                }
                acf {
                  product_type
                }
            }
            description
            grouped_products_nodes {
                id
                wordpress_id
                sku
                name
                price
                regular_price
                type
                slug
                images {
                    id
                    alt
                    src
                }
                categories {
                    wordpress_id
                    name
                }
            }
            acf {
                flavor
                effect
                product_type
                main_ingredients
                size
                dosage
                suggested_use
                supplement_facts_before
                ingredients_amounts
                supplement_facts_after
                ingredients
            }
        }
        site {
            siteMetadata {
                title
            }
        }
        ingredients: allWordpressPost(filter: {categories: {elemMatch: {name: {regex: "/Ingredients/i"}}}}) {
            nodes {
                id
                title
                slug
                wordpress_id
                featured_media {
                    source_url
                    alt_text
                }
            }
        }
    }
`;
