import React, { Component, createRef } from "react";
import Accordion from "../accordion";
import ProductDetails from "./product-details/product-details";
import Rating from "react-rating";
import CustomerReviews from "./reviews/customer-reviews";

export default class AccordionSection extends Component {

  constructor (props) {
    super(props);
    this._accordion = createRef();
  }

  renderAvgRating = (avgRating) => {
    return (
      <Rating
        className="star-rating readonly is-size-6 has-text-black"
        readonly={true}
        initialRating={avgRating}
        emptySymbol={<label className="star-rating__star">★</label>}
        fullSymbol={<label className="star-rating__star is-selected">★</label>}
      />
    );
  };

  render () {
    const {
      product,
      productDosage
    } = this.props;


    return (
      <section className="section" style={{paddingTop:'25px'}}>
        <div className="container">
          <Accordion className="product-accordion" ref={this._accordion}
            data={[
              {
                title: <h4>SEE PRODUCT DETAILS</h4>,
                content: <ProductDetails product={product} productDosage={productDosage}/>
              }
            ]}
            onItemOpened={this.accordionHeightChanged}
            onItemClosed={this.accordionHeightChanged}
          />
        </div>
      </section>
    );
  }

  accordionHeightChanged = () => {
    this.props.accordionHeightChanged && this.props.accordionHeightChanged();
  };
}
