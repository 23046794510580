import React, { Component, createRef } from "react";
import Highlightable from "./highlightable";
import SvgRelatedProductBlob from "./product/svgRelatedProductBlob.js";
import {Link} from "gatsby"

import anime from "animejs";
import _get from "lodash.get";
import { createIntersectionObserver } from "../lib/intersection-observer";
import "./relatedProduct.scss";

export default class RelatedProduct extends Component {

  constructor (props) {
    super(props);
    this._btnAddToBag = createRef();
  }

  componentDidMount () {
    this.inObserver = createIntersectionObserver(this._btnAddToBag.current, {
      onEnterViewportScrollDown: this.handleEnterViewportScrollDown,
      onExitViewportScrollUp: this.handleExitViewportScrollUp
    });
  }

  componentWillUnmount () {
    this.inObserver && this.inObserver.disconnect();
  }

  handleEnterViewportScrollDown = () => {
    anime.remove("#blob-background");
    anime({ targets: "#blob-background", opacity: "1.0", easing: "linear", duration: 250, scaleX: 1.1, scaleY: 1.1 });
  };

  handleExitViewportScrollUp = () => {
    anime.remove("#blob-background");
    anime({ targets: "#blob-background", opacity: "0.0", easing: "linear", duration: 2000, scaleX: 1, scaleY: 1 });
  };

  render () {

    const imageSrc = _get(this.props, "images[0].src");

    return (
      <section className="section is-medium related-product-section">
        <div className="container">
          <div className="columns is-reversed-mobile is-vcentered">
            <div className="column is-4 has-text-centered-mobile">
              <h3 className="has-text-weight-bold">
                <Highlightable>Related product</Highlightable>
              </h3>
              <h1>{this.props.name}</h1>
              <span className="product-description has-text-black is-size-5" dangerouslySetInnerHTML={{
                __html: `${this.props.description}`
              }} />
              <Link to={`/products/${this.props.slug}`} style={{ textDecoration: `none` }}>
              <button ref={this._btnAddToBag} className="button is-secondary">VIEW PRODUCT</button>
              </Link>
            </div>
            <div className="column has-text-centered related-product-item">
              <div className="is-relative">
                <SvgRelatedProductBlob id="blob-background" flavor={this.props.slug} style={{
                  opacity: "0.0",
                  position: "absolute",
                  top: 0, right: 0, left: 0, bottom: 0, margin: "auto", zIndex: -1
                }} />
                {imageSrc && <img src={imageSrc} alt={this.props.name} />}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
