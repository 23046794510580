import React from "react";
import "./select-product-dosage.scss";
import SelectDropdown from "../form/select-dropdown";
import SelectDropdownUp from "../form/select-dropdown-up";

const SelectProductDosage = (props) => {
  let selectedProductDosage, restProductDosage;
  switch (props.selectedOption) {
    case "cbd-vegan-gummies-passion-fruit-300mg":
    case "cbd-vegan-gummies-acai-blast-300mg":
    case "cbd-vegan-gummies-fresh-watermelon-300mg":
    case "cbd-topicals-face-cream":
      selectedProductDosage =  { value: "300", tag: "300 MG", subTag: " CBD", slug: props.selectedOption };
      restProductDosage = [];
      break;
    case "cbd-topicals-pain-cream":
    case "cbd-topicals-body-lotion":
      selectedProductDosage =  { value: "500", tag: "500 MG", subTag: " CBD", slug: props.selectedOption };
      restProductDosage = [];
      break;
    default:
      selectedProductDosage =  { value: "500", tag: "500 MG", subTag: " CBD", slug: props.selectedOption };
      restProductDosage = [
        { value: "1000", tag: "1000 MG", subTag: " CBD", slug: props.selectedOption }
      ];
      break;
  }

  return (
    (typeof props.isUp != "undefined" && props.isUp) ?
    <SelectDropdownUp selectedOption={selectedProductDosage} restOptions={restProductDosage} onChange={props.onChange}/>
    :
    <SelectDropdown selectedOption={selectedProductDosage} restOptions={restProductDosage} hasWhite={props.hasWhite} onChange={props.onChange}/>


  )
};

export default SelectProductDosage;