import React, { Component } from "react";
import Rating from "react-rating";
import FlavorSelect from "../form/flavorSelect";
import { isMobile } from "react-device-detect";
import { renderTags } from "../../utilities/product";
import QuantityButton from "../form/quantityButton";
import { isBrowser } from "react-device-detect";
import VariableHighlightedLabel from "../variableAnimatedDivider";
import DirectAnimatedDivider from "../directAnimatedDivider";
import AddToCartButton from "../form/add-to-cart-button";
import SelectProductDosage from "./select-product-dosage";
import SelectProductVariant from "./select-product-variant";
import { Waypoint } from "react-waypoint";
import _get from "lodash.get";
import "./ProductHero.scss";

const SvgProductHeaderBlob = props => (
  <svg viewBox="0 0 1804.79271 1518.82651" {...props}>
    <path
      d="M1743.87717,1309.159c-174.117,302.06656-558.48689,243.91721-1016.58361,38.839C264.928,1141.00875-287.46125,731.52335,173.53012,165.2023,393.6359-105.19439,975.23766-21.86844,1380.05447,257.40391,1710.13435,485.11725,1912.97786,1015.79489,1743.87717,1309.159Z"
      fill={props.fill}
      className="product-header-blob" />
  </svg>
);

export default class ProductHero extends Component {

  render () {

    const { product, reviewsSummary = {}, avg_rating, onReviewsClick, productDosage, notifyMeClick } = this.props;
    const { total_reviews = 0 } = reviewsSummary;

    let color;
    switch (product.slug) {
      case "cbd-oil-mighty-mango":
        color = "#ff7f40";
        break;
      case "cbd-oil-mint-bliss":
        color = "#91cfaa";
        break;
      case "cbd-oil-french-vanilla":
        color = "#f1ebbf";
        break;
      case "cbd-oil-zesty-lemon":
        color = "#fadb00";
        break;
      case "cbd-topicals-pain-cream":
      case "cbd-topicals-body-lotion":
      case "cbd-topicals-face-cream":
        color = "transparent";
        break;
      default:
        color = "transparent";
        break;
    }

    const imageSrc = (productDosage === "500" || productDosage === "300") ? _get(product, "images[1].src"):_get(product, "images[3].src");
    const imageAlt = _get(product, "images[1].alt") || product.name;
    
    

    return (
      <Waypoint
        onEnter={this.handleEnterViewport}
        onLeave={this.handleExitViewport}>
      <section className="hero has-yellow-background has-absolute-navbar is-fullheight">

        <div id="product-hero" className="hero-body">
          {product.slug.indexOf("vegan-gummies") !== -1 ?
            <div className="vegan-gummies-adjust">
                  <figure className="image product-hero-image is-5by4">
                    <SvgProductHeaderBlob fill={color} className="product-header-blob-svg" />
                    {imageSrc && <img src={imageSrc} alt={imageAlt} />}
                  </figure>
                </div>
                :
                false
          }
          <div className="container">
            <div className="columns is-centered is-vcentered">
              
              <div className={`column ${product.slug.indexOf("vegan-gummies") !== -1 ? "vegan-gummies-adjust-mobile":""}`}>
                <figure className="image product-hero-image is-5by4">
                  <SvgProductHeaderBlob fill={color} className="product-header-blob-svg" />
                  {imageSrc && <img src={imageSrc} alt={imageAlt} />}
                </figure>
              </div>
              
              <div className={`column is-5${product.slug.indexOf("vegan-gummies") !== -1 ? " is-offset-6":""}`}>

                <h1 className="has-text-white">{product.acf.product_type}</h1>
                <span id="product-page-description" className="has-text-white" dangerouslySetInnerHTML={{ __html: `${product.description}` }} />
                <br />

                <div className="is-inline-block mb-4 cursor-pointer" aria-label="Show reviews" onClick={onReviewsClick}>
                  <div className="columns is-mobile is-vcentered is-gapless">
                    <div className="column is-narrow">
                      <Rating
                        className="star-rating readonly has-text-white"
                        readonly={true}
                        initialRating={avg_rating}
                        emptySymbol={<label className="star-rating__star">☆</label>}
                        fullSymbol={<label className="star-rating__star is-selected">★</label>}
                      />&nbsp;
                    </div>
                    <div className="column">
                      <p className="is-inline-desktop has-text-light">{avg_rating.toFixed(1)} ({total_reviews} Reviews)</p>
                    </div>
                  </div>
                </div>
                <div className="product-selection">
                  <SelectProductDosage selectedOption={product.slug} onChange={this.props.handleDosageChange}/>
                  <FlavorSelect selectedOption={product.slug} onChange={this.props.handleFlavorChange}/>
                  {"outofstock" !== product.stock_status ?
                  <SelectProductVariant product={product} value={this.props.productVariant} onChange={this.props.handleProductVariantSelect} productDosage={productDosage}/>
                  :
                  false
                  }
                </div>
                <div className="quantity-buttons-container">
                {"outofstock" === product.stock_status ?
                  <div>
                    <button className="button is-primary is-secondary" style={{background: 'transparent', color: '#fff', border: '1px solid #fff', display: 'block', marginBottom: '25px'}}>Sold Out</button>
                    <button className="button is-primary" onClick={notifyMeClick}>Notify me when available</button>
                  </div>
                  :
                  <AddToCartButton onClick={this.addToCart} />
                  
                 }
                </div>

              </div>
            </div>

          </div>
        </div>
        <div className="product-footer">
              <div className="container">
              <DirectAnimatedDivider className="divider1" color="white" />
              </div>
              <div className="container">
                <div className="columns is-vcentered is-centered is-variable">
                  <div className="column">
                    <img src={require("../../images/bag/shipping.svg")} style={isMobile ? { marginBottom: "0px", marginRight:"5px" }:{ marginBottom: "0px" }} className="is-inline is-vertical-align" alt="" />&nbsp;&nbsp;
                    <h4 className="has-text-white is-inline is-vertical-align">1-3 Day Shipping</h4>
                  </div>
                  <div className="column">
                    <img src={require("../../images/bag/cancel.svg")} style={isMobile ? { marginBottom: "0px", marginRight: "10px" }: { marginBottom: "0px" }} className="is-inline is-vertical-align" alt="" />&nbsp;&nbsp;
                    <h4 className="has-text-white is-inline is-vertical-align">Cancel Anytime</h4>
                  </div>
                  <div className="column">
                    <img src={require("../../images/bag/satisfaction.svg")} style={{ marginBottom: "0px" }} className="is-inline is-vertical-align" alt="" />&nbsp;&nbsp;
                    <h4 className="has-text-white is-inline is-vertical-align">100% Satisfaction Guaranteed</h4>
                  </div>
                </div>
              </div>
            </div>
      </section>
      </Waypoint>
    );
  }

  handleAmountToggle = (amount) => {
    this.props.handleAmountToggle && this.props.handleAmountToggle(amount);
  };

  addToCart = () => {
    this.props.addToCart && this.props.addToCart(this.props.product, {
      product_id: this.props.product.id,
      quantity: this.props.quantity
    });
  };

  handleEnterViewport = () => {
    const checkoutFooter = document.getElementById("checkout-footer");
    checkoutFooter.classList.add("hide");
  }

  handleExitViewport = () => {
    const checkoutFooter = document.getElementById("checkout-footer");
    checkoutFooter.classList.remove("hide");
  }
}
