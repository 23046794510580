import React, { Component } from "react";
import auth from "../../../lib/auth";
import "./review-modal.scss";
import { renderTags } from "../../../utilities/product";
import Rating from "react-rating";
import AnimatedDoneButton from "../../form/animated-done-button";
import yotpo from "../../../lib/yotpo";
import _get from "lodash.get";

export default class ReviewModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isActive: false,

      readonly_name: false,
      readonly_email: false,

      // form data
      product_id: props.product.wordpress_id,
      rating: null,
      name: '',
      email: '', // this required by yotpo
      title: '',
      text: '',

      // form errors
      error_rating: null,

      // indicators
      sending: false,
      show_sent: false
    };
  }

  getFormData = () => ({
    product_id: this.state.product_id,
    rating: this.state.rating,
    name: this.state.name,
    email: this.state.email,
    title: this.state.title,
    text: this.state.text
  });

  open = () => {
    const state = { isActive: true };

    // fill name & email inputs from auth user and make these readonly
    if (auth.loggedIn) {
      state.name = auth.user.name;
      state.email = auth.user.email;
      state.readonly_name = !!state.name;
      state.readonly_email = !!state.email;
    }

    this.setState(state);
  };

  close = () => {
    this.setState({ isActive: false });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const formData = this.getFormData();

    // check has rating
    if (!formData.rating) {
      return this.setState({
        error_rating: "Please rate the product"
      });
    }

    // sending review
    this.setState({ sending: true });

    // API call create new review
    await yotpo.createReview({
      product_id: this.props.product.wordpress_id,
      product_title: this.props.product.name,
      product_url: `${window.location.origin}/products/${this.props.product.slug}/`,
      product_image_url: _get(this.props.product, "images[0].src"),
      display_name: formData.name,
      email: formData.email,
      review_content: formData.text,
      review_title: formData.title,
      review_score: formData.rating
    });

    this.setState({ sending: false, show_sent: true });

    // callback
    // this.props.afterSent && this.props.afterSent();
  };

  render () {
    const product = this.props.product;

    return (
      <div id="review-modal" className={`modal review-modal ${this.state.isActive ? "is-active" : ""}`}>
        <div className="modal-background" />
        <form className="modal-card" onSubmit={this.onSubmit}>

          {this.state.show_sent ?
            <section className="modal-card-body section has-text-centered">
              <br />
              <br />
              <h1 className="title is-y-marginless">Please check your mail</h1>
              <p>We sent an email with a link to confirm your review</p>
              <br />
              <br />
            </section>
            :

            <section className="modal-card-body">
              <h1 className="title">Write a review</h1>

              {/* product */}
              <div className="field field-product">
                <div className="columns is-vcentered is-mobile">
                  <div className="column is-narrow">
                    <figure className="image is-square is-64x64 product-image">
                      <img src={product.images[0].src} alt={product.name} />
                    </figure>
                  </div>
                  <div className="column">
                    <h4>{product.name}</h4>
                    <p>{renderTags(product)}</p>
                  </div>
                </div>
              </div>

              {/* rating */}
              <div className="field field-rating">
                <div className="columns is-vcentered is-mobile">
                  <div className="column is-narrow">
                    <Rating
                      className="star-rating"
                      initialRating={this.state.rating}
                      emptySymbol={<label className="star-rating__star">☆</label>}
                      fullSymbol={<label className="star-rating__star is-selected">★</label>}
                      onChange={rating => this.setState({ rating, error_rating: null })}
                    />
                  </div>
                  <div className="column">
                    {this.state.error_rating ?
                      <p className="has-text-danger">{this.state.error_rating}</p> :
                      <p>Your rating</p>}
                  </div>
                </div>
              </div>

              {/* name */}
              <div className="field field-name">
                <div className="control">
                  <input className="input" type="text" name="name" placeholder="Name"
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                    required readOnly={this.state.readonly_name} />
                </div>
              </div>

              {/* email */}
              <div className="field field-email">
                <div className="control">
                  <input className="input" type="email" name="email" placeholder="E-mail"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                    required readOnly={this.state.readonly_email} />
                </div>
              </div>

              {/* how did you like */}
              <div className="field field-title">
                <div className="control">
                  <input className="input" type="text" name="title" placeholder="How did you like it?"
                    onChange={e => this.setState({ title: e.target.value })}
                    required />
                </div>
              </div>

              {/* review text */}
              <div className="field field-text">
                <div className="control">
                <textarea className="textarea" name="text" placeholder="Describe your experience with the product"
                  onChange={e => this.setState({ text: e.target.value })}
                  required />
                </div>
              </div>

              {/* submit */}
              <div className="field field-submit">
                <AnimatedDoneButton type="submit"
                  className={`button is-primary is-normal is-fullwidth ${this.state.sending ? "is-loading" : ""}`}
                  done={this.state.show_sent}
                  normalText="SEND REVIEW"
                  doneText="YOUR REVIEW SENT"
                />
              </div>

            </section>

          }
          <button type="button" className="modal-close is-large" onClick={this.close} aria-label="close" />
        </form>
      </div>
    );
  }
}
