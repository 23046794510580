import React, { Component } from "react";
import Benefits from "../benefits";

export default class BenefitsSection extends Component {

  render () {
    return (
      <section className="section">
        <div className="container">
          <Benefits />
        </div>
      </section>
    );
  }
}
